// module.exports = {
//     primary: [
//         '#034067',
//         '#055385',
//         '#1D81C2',
//         '#4393C7',
//     ],
// }

module.exports = {
    primary: [
        '#000C66', // highlighted text
        '#000000', // Header
        '#6B8BA1', // Links
        '#E45757', // ?
    ],
}


