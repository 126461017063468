import { slide as Menu } from 'react-burger-menu'
import * as React from "react"
import "./layout.css"
import { rhythm } from '../utils/typography'
import styled from '@emotion/styled'
import { css } from '@emotion/css'

var styles = {
    bmBurgerButton: {
        position: 'relative',
        width: '30px',
        height: '10px',
        left: '20px',
        top: '5vh',
        zIndex: -1000,
    },
    bmBurgerBars: {
        background: '#f8f8f8',
        height: '2px'
    },
     bmBurgerBarsHover: {
        background: '#fd3'
     },
    bmCrossButton: {
        right: '24px',
        height: '28px',
        width: '28px'
    },
    bmCross: {
        height: '28px',
        background: '#fff'
    },
    // bmMenuWrap: {
    //     position: 'relative',
    // },
    bmMenu: {
        background: '#27292E',
        fontSize: '1.15em',
        position: `relative`,
    },
    bmMorphShape: {
        fill: '#373a47',
    },
    bmItemList: {
        color: '#b8b7ad',
        padding: '0.8em'
    },
    bmItem: {
        display: 'flex',
        flexDirection: 'columns',
        color: '#fff'
    },
    bmOverlay: {
        background: 'rgba(0, 0, 0, 0.3)'
    }
}

class MobileNav extends React.Component {

    render () {
        return (
            <Menu left noOverlay styles={ styles } pageWrapId={ "scaleRotate" } outerContainerId={ "outer-container" }>
                <a id="home" className="menu-item" href="/">Main</a>
                <a id="about" className="menu-item" href="/projects">Projects</a>
                <a id="contact" className="menu-item" href="/resume/">Resume</a>
            </Menu>
        );
    }
}

export default MobileNav