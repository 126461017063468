import { graphql, Link, StaticQuery }  from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { rhythm } from '../utils/typography'
import * as colors from '../../colors'

import { FaFacebook, FaGithub, FaLinkedin, FaTelegram} from 'react-icons/fa'

import styled from '@emotion/styled'
import { css } from '@emotion/css'
import Headroom from "react-headroom"
//import styled from 'react-emotion'  // WTF with this library
//import { css } from '@emotion/css'
import MobileNav from "./ham-menu"

import Logo from "../images/lookingup-circle.png"




const Outer = styled('div')`
  // box-shadow: 0px 0px 10px ${colors.primary[1]};
  overflow: hidden;
  background-color: ${colors.primary[1]};
  transform: translate3D(0,0,0);
  width: 100%;
  z-index: 2;
  padding: 0 ${rhythm(0.5)};
  display: flex;
  align-items: center;
  .filler {
    flex-grow: 1;
  }
  // Enable to turn off the header for phones
  // @media (max-width: 991px) {
  //   display: none;
  // } 
  a {
    text-decoration: none !important;
    display: flex;
    color: #fff;
    padding: ${rhythm(0.3)} ${rhythm(0.5)};
    font-size: 11pt;
    cursor: pointer;
    transition: color 0.1s, background-color 0.1s; 
    &:hover {
      background-color: #ffffffc0;
      color: ${colors.primary[0]} !important;
    }
  }
  @media print {
    visibility: hidden;
    padding: 0;
  }
`

var styles = {
    bmBurgerButton: {
        position: 'relative',
        width: '30px',
        height: '15px',
        left: '10px',
        top: '3vh',
        zIndex: -1000,
    },
    bmBurgerBars: {
        background: '#f8f8f8',
        height: '3px'
    },
    bmBurgerBarsHover: {
        background: '#fd3'
    },
    bmCrossButton: {
        right: '24px',
        height: '28px',
        width: '28px'
    },
    bmCross: {
        height: '28px',
        background: '#fff'
    },
    bmMenuWrap: {
        display: 'flex',
        position: 'relative',

    },
    bmMenu: {
        background: '#27292E',
        fontSize: '1.15em',
        position: `relative`,
    },
    bmMorphShape: {
        fill: '#373a47',
    },
    bmItemList: {
        color: '#b8b7ad',
        padding: '1.2em'
    },
    bmItem: {
        display: 'flex',
        flexDirection: 'columns',
        color: '#fff',
        alignContent:'space-between'
    },
    bmOverlay: {
        background: 'rgba(0, 0, 0, 0.3)'
    }
}

const HamMenu = styled('div')`
 
  @media (min-width: 991px) {
    display: none;
  } 

`

const activeLinkCss = css(`
  background-color: #ffffff20;
  color: #fff;
`)

const SocialLinksArea = styled('div')`
  display: flex;
  margin-right: ${rhythm(0.2)}; 
  & > * + * {
    margin-left: ${rhythm(0.5)}; 
  }
  a {
    padding: ${rhythm(0.3)} ${rhythm(0.3)} !important;
  }  
`

const Langs = styled('div')`
  display: flex;
  background-color: ${colors.primary[1]};
  margin-right: ${rhythm(0.2)}; 
  & > * + * {
    margin-left: ${rhythm(0.5)}; 
  }
  a {
    padding: ${rhythm(0.3)} ${rhythm(0.3)} !important;
  }  
`

const Header = ({ postKind }) =>
    <Headroom
        className={css(`
      @media print {
        visibility: hidden;
      }
      .headroom {
        transition: opacity 0.5s;
      }
      .headroom--scrolled {
        opacity: 0.9;
      }
    `)}
    >
  {/*      <MobileNav className={css(`(@media (min-width: 1100) {*/}
  {/*  display: none;*/}
  {/*})`)}>*/}

  {/*      </MobileNav>*/}

  {/*<HamMenu>*/}
  {/*      <Menu left styles={ styles } >*/}
  {/*          <a id="home" className="menu-item" href="/">Main</a>*/}
  {/*          <br />*/}
  {/*          <a id="about" className="menu-item" href="/projects">Projects</a>*/}
  {/*          <br />*/}
  {/*          <a id="contact" className="menu-item" href="/resume/">Resume</a>*/}
  {/*          <br />*/}
  {/*          <a id="contact" className="menu-item" href="/myterest/">Draw</a>*/}
  {/*          <br />*/}
  {/*          <a id="contact" className="menu-item" href="/geoarchive/">GeoPlans</a>*/}
  {/*          <br />*/}

  {/*          <a href="https://t.me/userpav" target="_blank" rel="noopener noreferrer"><FaTelegram/></a>*/}
  {/*          <br />*/}
  {/*          <br />*/}
  {/*          <a href="https://www.facebook.com/userpav" target="_blank" rel="noopener noreferrer"><FaFacebook/></a>*/}
  {/*          <br />*/}
  {/*          <br />*/}
  {/*          <a href="https://github.com/FTi130" target="_blank" rel="noopener noreferrer"><FaGithub/></a>*/}
  {/*      </Menu>*/}
  {/*</HamMenu>*/}

        <Outer>
            {/*<Logo/>*/}
            {/*<MobileNav>*/}
            {/*</MobileNav>*/}
            <Link to="/" activeClassName={activeLinkCss}>Main</Link>

            <Link
                to="/projects/" // /projects/
                className={postKind === 'project' ? activeLinkCss : undefined}
                activeClassName={activeLinkCss}>
                Projects
            </Link>


            {/*<Link to="/resume/" activeClassName={activeLinkCss}>Resume</Link>*/}
            <Link to="/maps/where-to-go/" activeClassName={activeLinkCss}>Travel</Link>
            {/*<Link to="/" partiallyActive={true} activeClassName={activeLinkCss}>Home</Link>*/}

            {/*<div className="filler"/>*/}
            {/*<Langs>*/}
            {/*    <Link to="/de/main/" partiallyActive={true}*/}
            {/*          activeClassName={activeLinkCss}>DE*/}
            {/*    </Link>*/}
            {/*</Langs>*/}

            <div className="filler"/>
            <SocialLinksArea>
                <a href="https://t.me/messelinks" target="_blank" rel="noopener noreferrer"><FaTelegram/></a>
                <a href="https://www.facebook.com/userpav" target="_blank" rel="noopener noreferrer"><FaFacebook/></a>
                <a href="https://github.com/FTi130" target="_blank" rel="noopener noreferrer"><FaGithub/></a>
                {/*<a href="https://www.linkedin.com/in/pavel-popov-472450156/" target="_blank" rel="noopener noreferrer"><FaLinkedin/></a>*/}
                {/*<a href="https://www.behance.net/userpav" target="_blank" rel="noopener noreferrer"><FaBehance/></a>*/}
            </SocialLinksArea>
        </Outer>
    </Headroom>

export default Header

// Here starts defaults


/*
const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `rebeccapurple`,
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1rem 1.0875rem`,
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `black`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
*/
